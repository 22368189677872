
@import './material';
@import './widths';

@import './login-material.scss';
@import './buttons.scss';
@import './text.scss';
@import './dots-menu.scss';
@import './table.scss';
@import './drag-n-drop-projects.scss';
@import './forms.scss';
@import './labels.scss';

html, body { 
  height: 100vh; 
  // overflow: hidden;
}

body, html {
  background: #f5f5f5;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

* {
  font-family: 'Roboto', sans-serif;
}

.wdragleave {
  body {
    background-color: #d3d3d3;
  }

}

.wbox {
  width: $columnWidth;
  padding: 1.5rem;
  // margin: 0 0.3rem;
  background-color: white;
  box-sizing: border-box;

}

.pointer {
  cursor: pointer;
  user-select: none;
}

.scrolly {
  overflow-y: scroll;
  overflow-x: hidden;
  // height: 100vh;
  // height: calc(100vh - var(--headerHeight));
}

.scrolly::-webkit-scrollbar {
  width: 4px;
}
.scrolly::-webkit-scrollbar-thumb { /* Foreground */
   background: $swGrey;
   border-radius: 2px;
}
.scrolly::-webkit-scrollbar-track { /* Background */
  background: $swLighterGrey;
}

.full-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
}

section {
  margin: 1rem 0;
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.spacer {
  align-self: stretch;
  flex-grow: 1;
}


.row {
  display: flex;
  align-items: center;
  flex-direction: row;
  & > * {
      display: inline-flex;
  }
}

hr {
  height: 1px;
  border:0; 
  border-top: 1px solid #D3D3D3;
  padding: 0;
}

/* Responsive Styles */
@media screen and (max-width: $breakWidth) {

} 